import React from 'react';
import Button from "../../../UI/Button";
import {useTranslation} from "react-i18next";
import {useDish} from "../../../../utils/contexts/dish-context";
import {MdEco} from "react-icons/md";
import {doGetIngredientsImpact} from "../../../../logic/dish";
import {useApi} from "../../../../utils/hooks/api";

const ImpactButton = ({...props}) => {

    const {dish, setImpact, impact} = useDish();
    const {i18n, t} = useTranslation();
    const {call} = useApi();

    const handleCalculateImpact = async () => {

        await call(
            () => doGetIngredientsImpact(dish.id, i18n.language),
            (response) => {
                if (response && response.data) {
                    const {ingredients, totals} = response.data;
                    if (ingredients && totals) {
                        setImpact({ingredients, totals});
                    }
                }
            }
        )
    }

    return (
        <Button onClick={handleCalculateImpact}
                className={`flex flex-row items-center gap-1 uppercase ${impact ? "bg-gray-500" : ""}`} {...props}><MdEco/> {t('calculate_impact.calculate_environmental_impact')}
        </Button>
    );
};

export default ImpactButton;
