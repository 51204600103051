import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDish} from "../../../../utils/contexts/dish-context";
import {doGetDiets} from "../../../../logic/dish";
import DietIcon from "./DietIcon";
import Loading from "../../../UI/Loading";
import SubTitle from "../../../UI/Text/SubTitle";
import {useToast} from "../../../../utils/hooks/toast-hook";

const Diets = () => {
    const [dietsLoading, setDietsLoading] = useState(false);

    const {dish, diets, setDiets} = useDish();
    const {t} = useTranslation();
    const {displayWarning} = useToast();

    const reload = async () => {
        setDietsLoading(true);
        try {
            let diet_response = await doGetDiets(dish.id);
            if (diet_response) {
                if (diet_response.response) {
                    setDiets(diet_response.response);
                }
            }
        } catch (e) {
            console.log('error getting diets');
            displayWarning('Error getting diets')
        }

        setDietsLoading(false);
    }

    useEffect(() => {
        if (dish && dish.id)
            reload();
    }, [dish]);

    return (
        <div className="flex-1">
            {dietsLoading ?
                <div className="flex flex-row justify-start mb-2">
                    <Loading label={"Loading food regimes"}/>
                </div>
                :
                <div>
                    <SubTitle>{t('calculate_impact.food_regimes')}</SubTitle>
                    <div className="flex flex-row flex-wrap gap-2">
                        {diets && diets.length > 0 ?
                            diets.map((diet, i) => <DietIcon diet={diet} key={i}/>)
                            : <div className="text-gray-300 italic">{t('Food regimes not found')}</div>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default Diets;
