import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDish} from "../../utils/contexts/dish-context";
import Button from "../UI/Button";
import {useApi} from "../../utils/hooks/api";
import {doPostOffData} from "../../logic/barcode";
import {useToast} from "../../utils/hooks/toast-hook";


const OffDataDisplay = ({onclose}) => {
    const navigate = useNavigate();
    const {call, loading} = useApi();
    const {offData, barcode, setBarcode} = useDish();
    const {displayError, displayMessage} = useToast()

    const [toSave, setToSave] = useState(false)
    const [saved, setSaved] = useState(false)

    const handleGdo = () => {
        setBarcode(undefined);
        navigate('/gdo');
    }

    const handleAddOffData = async () => {
        setToSave(true);
        const data = {
            name: offData.name,
            brand: offData.brand,
            ingredients: offData.ingredients,
        }

        await call(
            () => doPostOffData(barcode, data),
            (res) => {
                displayMessage('OffData Saved');
                setSaved(true);
            },
            (err) => {
                displayError("Error in adding OffData:", err);
            }
        )
    }


    return (
        <>
            {(!toSave && !saved) ?
                <>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh'
                    }}>
                        <div style={{
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            padding: '20px',
                            maxWidth: '500px',
                            width: '100%',
                            textAlign: 'center',
                            position: 'relative'
                        }}>
                            <p className={'mb-8'}><strong>dati raccolti da Open Food Facts</strong></p>

                            <p><strong>Name:</strong> {offData.name}</p>
                            <p><strong>Brand:</strong> {offData.brand}</p>
                            <p><strong>Ingredients:</strong> {offData.ingredients}</p>
                            <p><strong>Nutrient Levels:</strong></p>
                            <p><strong>Fat:</strong> {offData.nutrient_levels.fat}</p>
                            <p><strong>Salt:</strong> {offData.nutrient_levels.salt}</p>
                            <p><strong>Saturated Fat:</strong> {offData.nutrient_levels['saturated-fat']}</p>
                            <p><strong>Sugars:</strong> {offData.nutrient_levels.sugars}</p>
                            <img src={offData.front_image} alt="Product"
                                 style={{maxWidth: '50%', height: 'auto', margin: '20px auto'}}/>
                            <p><strong>Last Edit Date:</strong> {offData.last_edit_date.toLocaleString()}</p>

                            <div style={{position: 'absolute', bottom: '-20px', left: '8px'}}>
                                <Button style={{width: '130px'}}
                                        onClick={() => {
                                            navigate('/gdo')
                                        }}>CLOSE</Button>
                            </div>
                            <div style={{position: 'absolute', bottom: '-20px', right: '8px'}}>
                                <Button style={{width: '130px'}}
                                        onClick={handleAddOffData}>SAVE TO MYCIA</Button>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>

                    <div style={{
                        borderRadius: '5px',
                        padding: '20px',
                        maxWidth: '500px',
                        width: '100%',
                        textAlign: 'center',
                        position: 'relative',

                    }}>
                        <p><strong>Dati Salvati con Successo!</strong></p>
                        <Button style={{width: '130px', marginTop: '10px'}}
                                onClick={handleGdo}
                        >TORNA A GDO</Button>
                    </div>
                </>
            }

        </>


    );
};

export default OffDataDisplay;
