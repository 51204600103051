import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDish} from "../../../utils/contexts/dish-context";
import {doPutDish} from "../../../logic/dish";
import SubTitle from "../../UI/Text/SubTitle";
import SelectedIngredient from "./SelectedIngredient";
import {useApi} from "../../../utils/hooks/api";

const DishIngredients = () => {
    const [selectedIngredients, setSelectedIngredients] = useState([]);

    const {dish, setDish} = useDish();
    const {call} = useApi();
    const {t, i18n} = useTranslation();

    const handleClick = async (val) => {
        let new_ingredients = [];

        if (selectedIngredients.some(ingr => ingr.id === val.id))
            new_ingredients = [...selectedIngredients.filter((ingr) => ingr.id !== val.id)];
        else
            new_ingredients = [...selectedIngredients, {...val}];

        setSelectedIngredients([...new_ingredients]);

        await handleEditIngredients(new_ingredients);
    }

    const handleEditIngredients = async (ingredients) => {
        let ids = ingredients.map(ingr => ingr.id.toString());

        if (ids) {
            await call(
                () => doPutDish(dish.id, ids, i18n.language),
                (response) => {
                    if (response) {
                        setDish(response.data);
                    }
                }
            );
        }
    }

    useEffect(() => {
        const {ingredients} = dish;
        if (ingredients && ingredients.length >= 0) {
            setSelectedIngredients(ingredients);
        }
    }, [dish.ingredients]);

    return (
        <div className="flex-1 flex flex-col">
            <SubTitle>{t('calculate_impact.the_dish_has_the_following_ingredients')}</SubTitle>
            <div className="">
                {selectedIngredients && selectedIngredients.length > 0 ?
                    <div className="flex flex-row flex-wrap gap-2">
                        {selectedIngredients.map((ingr, i) =>
                            <SelectedIngredient onDelete={handleClick}
                                                ingredient={ingr}
                                                key={`${i}_${ingr.id}`}/>
                        )}
                    </div>
                    :
                    <div>Nessun ingrediente selezionato</div>
                }
            </div>

        </div>
    );
};

export default DishIngredients;
