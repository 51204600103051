import React from 'react';

const Button = ({className = "", onClick, ...props}) => {
    return (
        <button {...props}
                onClick={onClick}
                className={`p-1 px-2 text-white bg-blue-800 hover:bg-blue-600 rounded text-center uppercase whitespace-nowrap ${className}`}>
            {props.children}
        </button>
    );
};

export default Button;
