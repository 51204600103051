import React, {useEffect, useRef} from 'react';

const BaseInput = ({className, ...props}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <input
            ref={inputRef}
            className={`${className} p-1 outline-none bg-black bg-opacity-5 shadow-inner focus:bg-mcia_orange focus:bg-opacity-10 rounded`}
            {...props}
        />
    );
}

export default BaseInput;
