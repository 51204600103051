import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDish} from "../../../utils/contexts/dish-context";
import {doPutIngredientsWeights} from "../../../logic/ingredients";
import BaseInput from "../../UI/BaseInput";
import Button from "../../UI/Button";
import OverlayContainer from "../../UI/OverlayContainer";
import {useApi} from "../../../utils/hooks/api";

const IngredientsWeightForm = () => {

    const [formIngredients, setFormIngredients] = useState([]);

    const {dish, updateIngredients, closeWeightModal} = useDish();
    const {call} = useApi();
    const {t, i18n} = useTranslation();

    const onGramsChange = (ingredient, weight) => {
        const _ingredients = formIngredients.map((ingr) =>
            ingr.id === ingredient.id ? {...ingr, weight: weight} : {...ingr}
        )
        setFormIngredients([..._ingredients]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = formIngredients.map((ingr) => {
            return {id: ingr.id, weight: ingr.weight ?? 0}
        });

        await call(
            () => doPutIngredientsWeights(dish.id, [...payload], i18n.language),
            (response) => {
                if (response && response.data && response.data.ingredients) {
                    updateIngredients(response.data.ingredients);
                    closeWeightModal();
                }
            }
        )
    }

    const handleUndo = () => {
        closeWeightModal();
    }

    useEffect(() => {
        const {ingredients} = dish;

        if (ingredients && ingredients.length > 0) {
            setFormIngredients([...ingredients]);
        }

    }, [])

    return (
        <OverlayContainer>
            <div className="p-4 bg-white rounded shadow-2xl">
                <div
                    className="mb-2 font-bold uppercase text-gray-700 ">{t('calculate_impact.change_ingredients_weight')}</div>
                <form onSubmit={handleSubmit}>
                    {formIngredients.map((ingr, i) =>
                        <div className="flex flex-row items-center justify-between mb-1" key={ingr.id}>
                            <div>{ingr.nome ?? ingr.name}</div>
                            <BaseInput name={`ingr_${i}`}
                                       className='w-24 text-right'
                                       value={ingr.weight ?? ''}
                                       type='number'
                                       required={true}
                                       placeholder='g'
                                       onChange={(e) => onGramsChange(ingr, e.target.value)}/>
                        </div>
                    )}
                    <div className="flex flex-row justify-between mt-2">
                        <button type='button' onClick={handleUndo}>{t('Undo')}</button>
                        <Button type='submit'>{t('Confirm')}</Button>
                    </div>
                </form>
            </div>
        </OverlayContainer>
    );
};

export default IngredientsWeightForm;
