import React from 'react';
import Description from "../components/UI/Text/Description";

const NotFound = () => {
    return (
        <div className="p-4 flex flex-row items-center">
            <Description className="font-medium">404 page not found</Description>
        </div>
    );
};

export default NotFound;