import {useState} from 'react';

const useModalStatus = () => {
    const [status, setStatus] = useState(false);

    const open = () => setStatus(true);
    const close = () => setStatus(false);

    return [status, open, close];
};

export {
    useModalStatus
}