import React from 'react';

const OverlayContainer = ({...props}) => {
    return (
        <div
            className="fixed top-0 left-0 right-0 bottom-0 flex flex-col items-center pt-4 p-1 sm:p-8 bg-black bg-opacity-30 z-10">
            {props.children}
        </div>
    );
};

export default OverlayContainer;