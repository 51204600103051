import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './locales/en/translation.json';
import it from './locales/it/translation.json';
import es from './locales/es/translation.json';
import fr from './locales/fr/translation.json';
import ja from './locales/ja/translation.json';

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translation: en,
            },
            it: {
                translation: it,
            },
            fr: {
                translation: fr,
            },
            es: {
                translation: es,
            },
            ja: {
                translation: ja,
            }
        }
    })
