import React from 'react';
import {useTranslation} from "react-i18next";

const IMAGE_ENDPOINT = process.env.PUBLIC_URL + "/assets/";

const AllergenIcon = ({allergen}) => {

    const {t} = useTranslation();

    return (
        <div className="flex flex-col items-center">
            <div>{t(`allergens.${allergen.allergia_nome}`)}</div>
            <img className="w-6 h-6 object-contain text-xs" src={IMAGE_ENDPOINT + allergen.allergia_icona} alt={t('Allergen image')}/>
        </div>
    );
};

export default AllergenIcon;
