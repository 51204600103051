import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Button from "../UI/Button";
import {useToast} from "../../utils/hooks/toast-hook";
import {doGetAllAllergies, doGetAllDiets} from "../../logic/barcode";
import AllergySelector from './AllergySelector';
import SelectedAllergies from './SelectedAllergies';
import DietSelector from "./DietSelector";
import SelectedDiets from "./SelectedDiets";
import UserInfoSelectors from "./UserInfoSelectors";

const UserInfoDisplay = ({onclose}) => {
    const navigate = useNavigate();

    return (
        <>
            <UserInfoSelectors/>
            <div className={'mt-2'}>
                <Button style={{width: '130px'}}
                        onClick={() => {
                            navigate('/gdo')
                        }}>go to Scan</Button>
            </div>
        </>
    );
};

export default UserInfoDisplay;
