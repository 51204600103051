import React from 'react';
import {IoMdNutrition} from "react-icons/io";
import {useDish} from "../../../utils/contexts/dish-context";
import {useTranslation} from "react-i18next";
import ImpactButton from "./Impact/ImpactButton";
import Button from "../../UI/Button";
import {RiScales2Line} from "react-icons/ri";

const DishActions = () => {

    const {t} = useTranslation();
    const {dish, openWeightModal, openNutritionalModal, ingredients_weight} = useDish();

    const handleOpenModal = () => {
        openWeightModal();
    }

    return (
        <div className="flex flex-col md:flex-row gap-2 my-4 py-4 justify-between border-y-2 border-gray-400">

            <div className="flex flex-col items-center">
                <Button onClick={handleOpenModal}
                        className="uppercase flex flex-row items-center gap-1">
                    <RiScales2Line/> {t('calculate_impact.change_ingredients_weight')}
                </Button>
                {!ingredients_weight > 0 &&
                    <div
                        className="text-sm font-medium text-yellow-500">{t('calculate_impact.total_weight_of_ingredients_is_0')}</div>
                }
            </div>

            <div className="flex flex-col items-center">
                <Button onClick={openNutritionalModal} className='uppercase flex flex-row items-center gap-1'>
                    <IoMdNutrition/> {t('calculate_impact.view_nutritional_values')}
                </Button>
            </div>

            <div className="flex flex-col items-center">
                <ImpactButton/>
                {!ingredients_weight > 0 &&
                    <div
                        className="text-sm font-medium text-red-600">{t('calculate_impact.insert_weight_of_ingredients_to_calculate_impact')}</div>
                }
            </div>

        </div>
    );
};

export default DishActions;
