import React from 'react';
import {useDish} from "../../../utils/contexts/dish-context";
import DishPhrase from "./DishPhrase";
import {useTranslation} from "react-i18next";

const DishDetails = () => {

    const {dish} = useDish();
    const {t} = useTranslation();

    return (
        <div className="border-2 rounded border-blue-400 p-2">
            <div className="text-xl uppercase font-bold">{dish.pia_nome}</div>
            <div className="flex flex-row text-sm italic">
                <span className="uppercase">{t('calculate_impact.compositions')}:&nbsp;&nbsp;</span>

                {
                    dish.compositions &&
                    dish.compositions.map((composition, index) => (
                        <div key={composition.id} className="flex-row">
                            <span className="uppercase">{composition.name}</span>
                            {index < dish.compositions.length - 1 && ',\u00A0'}
                        </div>
                    ))
                }


            </div>
            <DishPhrase/>
        </div>

    );
};

export default DishDetails;
