import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDish} from "../../../../utils/contexts/dish-context";
import {doGetNutritionalValuesOfDish} from "../../../../logic/dish";
import NutritionalTable from "./NutritionalTable";
import Loading from "../../../UI/Loading";
import OverlayContainer from "../../../UI/OverlayContainer";

const NutritionalForm = () => {

    const {t} = useTranslation();
    const {dish, closeNutritionalModal} = useDish();

    const [loading, setLoading] = useState(true);
    const [nutritionalValues, setNutritionalValues] = useState(null);

    const load = async () => {
        setLoading(true);
        let response = await doGetNutritionalValuesOfDish(dish.id);
        if (response && response.status === "success") {
            setNutritionalValues(response.response);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (dish)
            load();
    }, []);

    return (
        <OverlayContainer>
            <div className="p-4 bg-white rounded shadow-2xl" style={{minWidth: '300px'}}>
                <div className="mb-2 font-bold uppercase text-gray-700 ">{t('nutritional.nutritional_values')}</div>
                {loading ?
                    <Loading label={'Loading nutritional values'}/>
                    :
                    nutritionalValues ?
                        <NutritionalTable values={nutritionalValues}/>
                        :
                        <div>Nutritional values not loaded</div>
                }
                <div>
                    <button type="button"
                            className="hover:underline font-medium mt-2 "
                            onClick={closeNutritionalModal}>{t('Close')}</button>
                </div>
            </div>
        </OverlayContainer>
    );
};

export default NutritionalForm;
