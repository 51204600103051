import {useApp} from "../contexts/app-context";
import React from "react";

function useToast() {
    const {setToastContent, setToastStatus} = useApp();

    const DISAPPEAR_TIMER = 3000;

    function displaySuccess(message) {
        displayToast(
            <div className="bg-green-500 p-2 rounded mb-2 shadow-lg">{message}</div>
        );
    }

    function displayWarning(message) {
        displayToast(
            <div className="bg-yellow-600 p-2 rounded mb-2 shadow-lg">{message}</div>
        );
    }

    function displayError(message) {
        displayToast(
            <div className="bg-red-500 text-white p-2 rounded mb-2 shadow-lg">{message}</div>
        );
    }

    function displayMessage(message) {
        displayToast(
            <div className="bg-gray-200 p-2 rounded mb-2 shadow-lg">{message}</div>
        );
    }

    function displayToast(content) {
        setToastContent(content);
        setToastStatus(true);
        setTimeout(() => {
            setToastStatus(false);
        }, DISAPPEAR_TIMER);
    }

    return {
        displayMessage,
        displayWarning,
        displayError,
        displaySuccess
    }
}

export {
    useToast,
}
