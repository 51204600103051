import React, {useState} from 'react';
import Ingredients from "../Ingredients/Ingredients";
import DishDetails from "./DishDetails";
import Diets from "./Diet/Diets";
import Allergens from "./Allergens/Allergens";
import Impact from "./Impact/Impact";
import DishActions from "./DishActions";
import {useLocation} from "react-router-dom";
import Button from "../../UI/Button";
import {useTranslation} from "react-i18next";
import {useDish} from "../../../utils/contexts/dish-context";
import {GiArtificialHive} from "react-icons/gi";


const Dish = () => {
    const {t} = useTranslation();
    const {openAiDishDescriptionModal} = useDish()

    const handleOpenModal = () => {
        openAiDishDescriptionModal();
    }

    const location = useLocation();
    const showItems = () => {
        switch (location.pathname) {
            case '/gdo':
                return false;
            default:
                return true;
        }
    };

    return (
        <>
            <div className="p-2">
                <DishDetails/>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <Diets/>
                    <Allergens/>
                    <Impact/>
                </div>

                <Ingredients/>
                {showItems() &&
                    <>
                        <Button onClick={handleOpenModal}
                                className='uppercase flex flex-row items-center gap-1'>
                            <GiArtificialHive/> {t('calculate_impact.generate_ai_dish_description')}
                        </Button>
                        <DishActions/>
                    </>
                }
            </div>
        </>

    );
};

export default Dish;
