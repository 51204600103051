import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDish} from "../../../../utils/contexts/dish-context";

const Row = ({label, value, unit}) => {
    return (
        <tr className="border-l border-r">
            <td className="pl-1 border-r">{label}</td>
            <td className="text-right border-r pr-1">{unit}</td>
            <td className="text-right pr-1">{value}</td>
        </tr>
    )
}

const NutritionalTable = ({values}) => {

    const {t} = useTranslation();
    const {dish, getDishWeight, ingredients_weight} = useDish();

    return (
        <table className="w-full">
            <thead>
            <tr className="text-left">
                <th colSpan={3} className="text-right p-1">
                    <div>{t('nutritional.dish_weight')}: {ingredients_weight}g</div>
                    {!ingredients_weight > 0 &&
                        <div className="font-medium text-sm text-center text-yellow-600">
                            {t('nutritional.insert_weight_of_ingredients_to_calculate_nutritional_values')}
                        </div>
                    }
                </th>
            </tr>
            </thead>
            <tbody className="border-y">
            <Row label={t('nutritional.calorie')} value={values.calorie} unit='kcal'/>
            <Row label={t('nutritional.fibre')} value={values.fibre} unit='g'/>
            <Row label={t('nutritional.glucidi')} value={values.glucidi} unit='g'/>
            <Row label={t('nutritional.lipidi')} value={values.lipidi} unit='g'/>
            <Row label={t('nutritional.protidi')} value={values.protidi} unit='g'/>
            <Row label={t('nutritional.vitamina_a')} value={values.vitamina_a} unit='mg'/>
            <Row label={t('nutritional.vitamina_b')} value={values.vitamina_b} unit='mg'/>
            <Row label={t('nutritional.vitamina_c')} value={values.vitamina_c} unit='mg'/>
            <Row label={t('nutritional.vitamina_d')} value={values.vitamina_d} unit='mg'/>
            <Row label={t('nutritional.vitamina_e')} value={values.vitamina_e} unit='mg'/>
            <Row label={t('nutritional.vitamina_k')} value={values.vitamina_k} unit='mg'/>
            </tbody>
        </table>
    );
};

export default NutritionalTable;
