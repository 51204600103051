import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {doGetCompoundIngredients} from "../../../logic/ingredients";
import Loading from "../../UI/Loading";
import Tooltip from "../../UI/Tooltip";

const CompoundName = ({ingredient, className}) => {

    const {i18n} = useTranslation();

    const [ingredients, setIngredients] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleHover = async () => {
        setLoading(true);
        if(!ingredients.length > 0) {
            let response = await doGetCompoundIngredients(ingredient.id, i18n.language);
            if(response && response.response) {
                setIngredients(response.response[0].ingredients);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        setIngredients([]);
    }, [i18n.language])

    return (
        <Tooltip className={className ?? "mr-2"}
                 onMouseEnter={handleHover}
                 tooltip={
                    <div className="font-normal text-xs not-italic " style={{minWidth: "200px"}}>
                        {loading ? <Loading/>
                            :
                            <div className="leading-none">
                                <div className="font-medium block">{ingredient.name}</div>
                                <div className="font-normal leading-none" >
                                    {ingredients.map(ingr => ingr.name ?? ingr.text).toString().replaceAll(',', ', ')}
                                </div>
                            </div>
                        }
                    </div>} >
            <div className='flex-col'>
                <div>{ingredient.name}</div>
                <div className="text-xs block">{ingredient.it_name}</div>
            </div>

        </Tooltip>
    );
};

export default CompoundName;