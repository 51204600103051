import React from 'react';
import ImpactIngredientTooltip from "./ImpactIngredientTooltip";

const ImpactIngredientRow = ({ingredient}) => {
    return (
        <tr className="p-0 leading-none">
            <td><ImpactIngredientTooltip ingredient={ingredient} /></td>
            <td className="text-right">{ingredient.weight ?? 0}g</td>
            <td className="text-right">{parseFloat(ingredient.co2).toFixed(2)}g</td>
            <td className="text-right">{parseFloat(ingredient.h2o).toFixed(2)}l</td>
        </tr>
    );
};

export default ImpactIngredientRow;