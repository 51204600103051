import apiV3 from "../utils/apiV3";

const GSI_ENDPOINT = process.env.REACT_APP_GSI_ENDPOINT;
const key = process.env.REACT_APP_AUTH_GSI_KEY;
const secret = process.env.REACT_APP_AUTH_GSI_SECRET


async function doPostLogin(email, password) {
    let response = await apiV3.postEncoded('envimpact/login', {email, password});
    if (response) return response.data;
}

async function doGetWhoAmI() {
    let response = await apiV3.get('envimpact/whoami');
    if (response) return response.data;
}

export {
    doPostLogin,
    doGetWhoAmI,
}
