import React, {useState} from 'react';

const Tooltip = ({children, className= "",tooltip, onMouseEnter, ...props}) => {

    const [displayTooltip, setDisplayTooltip] = useState(false);

    const handleMouseEnter = () => {
        onMouseEnter && onMouseEnter();
        setDisplayTooltip(true);
    }

    const handleMouseLeave = () => {
        setDisplayTooltip(false);
    }

    return (
        <div className={`relative cursor-pointer ${className}`} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={`${displayTooltip ? "absolute" : "hidden"} bottom-6 left-0  bg-white p-2 rounded shadow border border-gray-200`} >
                {tooltip}
            </div>
            {children}
        </div>
    );
};

export default Tooltip;