import React, {useEffect, useState} from 'react';
import {doGetFoods} from "../../../logic/foods";
import {useTranslation} from "react-i18next";
import {useDish} from "../../../utils/contexts/dish-context";
import useFilter from "../../../utils/hooks/filter-hook";
import {doPutDish} from "../../../logic/dish";
import Loading from "../../UI/Loading";
import IngredientOption from "./IngredientOption";
import Description from "../../UI/Text/Description";
import BaseInput from "../../UI/BaseInput";
import SubTitle from "../../UI/Text/SubTitle";
import {useApi} from "../../../utils/hooks/api";

const SelectIngredients = () => {

    const {i18n, t} = useTranslation();
    const {dish, setDish} = useDish();
    const {call, loading} = useApi();

    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [ingredients, setIngredients] = useState([]);

    const filterByName = (ingredient) => {
        return ingredient.name.toLowerCase().includes(filterText.toLowerCase());
    }

    const {filterText, setFilterText, filteredData} = useFilter(ingredients, filterByName);

    const handleClick = async (val) => {
        let new_ingredients = [];
        const hasIngredient = dish.ingredients.some(ingr => ingr.id.toString() === val.id.toString());
        if (hasIngredient)
            new_ingredients = [...dish.ingredients.filter((ingr) => ingr.id.toString() !== val.id.toString())];
        else
            new_ingredients = [...selectedIngredients, {...val}];

        setSelectedIngredients([...new_ingredients]);

        await handleEditIngredients(new_ingredients);
    }

    const handleEditIngredients = async (ingredients) => {
        let ids = ingredients.map(ingr => ingr.id.toString());

        if (ids) {
            await call(
                () => doPutDish(dish.id, ids, i18n.language),
                (response) => {
                    if (response) {
                        setDish(response.data);
                    }
                }
            );
        }
    }

    const load = async () => {
        await call(
            () => doGetFoods(i18n.language),
            (response) => {
                if (response && response.data) {
                    setIngredients(response.data);
                }
            }
        );
    }

    useEffect(() => {
        const _ingredients = dish.ingredients;
        if (_ingredients && _ingredients.length >= 0) {
            setSelectedIngredients(_ingredients);
        }
    }, [dish.ingredients]);

    useEffect(() => {
        load();
    }, []);

    return (
        <div className="flex-1">
            <SubTitle>{t('calculate_impact.search_ingredients')}</SubTitle>
            <BaseInput type="text"
                       className="w-full"
                       value={filterText}
                       onChange={(e) => setFilterText(e.target.value)}
                       placeholder={t('calculate_impact.filter_ingredients')}
            />

            <div>
                <div className="overflow-auto h-32 mt-2">
                    {loading ? <Loading label='Loading ingredients'/>
                        : ingredients && filteredData.length > 0 && filterText.length > 2
                            ? filteredData.map((ingredient, i) =>
                                <IngredientOption ingredient={ingredient}
                                                  onClick={handleClick}
                                                  dishIngredients={dish && dish.ingredients ? dish.ingredients : []}
                                                  key={`${ingredient.id}`}/>)
                            : filteredData.length > 0 && filterText.length > 0
                                ?
                                <Description>{t('calculate_impact.type_at_least_3_characters_to_search_an_ingredient')}</Description>
                                : filterText.length > 0
                                    ? <Description>{t("calculate_impact.no_ingredients_found")}</Description>
                                    :
                                    <Description>{t('calculate_impact.type_the_name_of_the_ingredient_to_search_for_it')}</Description>
                    }
                </div>
            </div>
        </div>
    );
};

export default SelectIngredients;
