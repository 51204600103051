import React from 'react';
import Button from "../components/UI/Button";
import {useNavigate} from "react-router-dom";


const Home = () => {

    const navigate = useNavigate();


    const handleClick = () => {
        navigate('/login');
    }

    return (
        <div className="flex flex-col items-center gap-2 p-2 text-center">
            <h1 className="text-4xl font-bold uppercase text-gray-800 mt-4">Discover MyCIA tools</h1>
            <h2 className="text-2xl mb-4">Test the environmental impact of your dishes</h2>
            <Button onClick={handleClick} className="text-lg">Click to enter</Button>
        </div>
    );
};

export default Home;
