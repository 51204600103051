import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useDish} from "../../../../utils/contexts/dish-context";
import SubTitle from "../../../UI/Text/SubTitle";
import ImpactIngredientRow from "./ImpactIngredientRow";

const Impact = () => {

    const {impact, setImpact, dish} = useDish();
    const {t} = useTranslation();

    const getC02proportion = () => {
        if (impact?.totals?.co2 && impact?.totals?.weight) {
            return (100 * impact.totals.co2) / impact.totals.weight
        }
        return 0;
    }

    const getH20proportion = () => {
        if (impact?.totals?.h2o && impact?.totals?.weight) {
            return (100 * impact.totals.h2o) / impact.totals.weight
        }
        return 0;
    }

    useEffect(() => {
        if (dish && dish.id) {
            setImpact(null)
        }
    }, [dish])

    return (
        <div className="flex-1 w-full md:col-span-2 lg:col-span-1 ">
            {impact ?
                <table className="w-full text-left">
                    <thead>
                    <tr className="border-b">
                        <th className="font-normal"><SubTitle
                            className="text-sm sm:text-normal">{t('calculate_impact.environmental_impact')}</SubTitle>
                        </th>
                        <th className="text-right font-normal text-xs">{t('weight')}</th>
                        <th className="text-right" title="Grammi di CO2 prodotta">CO2</th>
                        <th className="text-right" title="Litri d'acqua utilizzati">H20</th>
                    </tr>
                    </thead>
                    <tbody className="text-xs">
                    {impact.ingredients && impact.ingredients.length > 0 ?
                        impact.ingredients.map((ingr) =>
                            <ImpactIngredientRow ingredient={ingr} key={ingr.id}/>
                        )
                        :
                        <tr>
                            <td colSpan={4}>Ingredienti non presenti</td>
                        </tr>
                    }
                    </tbody>
                    <tfoot>
                    <tr className=" font-bold border-t">
                        <td>TOTALI</td>
                        <td className="text-right">{parseFloat(impact.totals?.weight).toLocaleString('it')}g</td>
                        <td className="text-right">{parseFloat(impact.totals?.co2).toFixed(2)}g</td>
                        <td className="text-right">{parseFloat(impact.totals?.h2o).toFixed(2)}l</td>
                    </tr>
                    <tr className="leading-none text-sm">
                        <td></td>
                        <td className="text-right">per 100g</td>
                        <td className="text-right">{getC02proportion().toFixed(2)}g</td>
                        <td className="text-right">{getH20proportion().toFixed(2)}l</td>

                    </tr>
                    </tfoot>
                </table>
                :
                <div>
                    <SubTitle>{t('calculate_impact.environmental_impact')}</SubTitle>
                    <div className="italic text-gray-400 text-sm">
                        {t('calculate_impact.environmental_impact_description')}
                    </div>
                </div>
            }

        </div>
    );
};

export default Impact;
