import React from 'react';
import SelectIngredients from "./SelectIngredients";
import DishIngredients from "./DishIngredients";
import {useLocation} from "react-router-dom";

const Ingredients = () => {

    const location = useLocation();
    const showItems = () => {
        switch (location.pathname) {
            case '/gdo':
                return false;
            default:
                return true;
        }
    };

    return (
        <div className="flex flex-col md:flex-row gap-4 mt-4">
            <DishIngredients/>
            {showItems() && <SelectIngredients/>}
        </div>
    );
};

export default Ingredients;
