import React from 'react';

const Panel = ({className, ...props}) => {
    return (
        <div className={`${className} p-4 rounded-lg bg-gray-100`} {...props}>
            {props.children}
        </div>
    );
};

export default Panel;