import React, {useState, useEffect} from 'react';
import {CiCircleRemove} from "react-icons/ci";
import {useDish} from "../../utils/contexts/dish-context";

const SelectedAllergies = ({selectedAllergies, deleteFromSelected}) => {
    const {filteredAllergies, setFilteredAllergies} = useDish()

    useEffect(() => {
        let uniqueAllergies = [...new Map(selectedAllergies.map(allergy => [allergy.id, allergy])).values()];
        setFilteredAllergies(uniqueAllergies);
    }, [selectedAllergies]);

    const handleClick = (allergy) => () => {
        deleteFromSelected(allergy);
    }

    return (
        <div>
            {filteredAllergies.map(allergy => (
                <div key={allergy.id} style={{fontSize: 'x-large'}}>
                    {allergy.name}
                    <button className={'ml-2'} onClick={handleClick(allergy)}><CiCircleRemove/></button>
                </div>
            ))}
        </div>
    );
};

export default SelectedAllergies;
