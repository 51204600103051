import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {doGetLanguages} from "../../logic/languages";
import {useApi} from "../../utils/hooks/api";

const VALUES = [
    {value: 'en', label: 'English'},
    {value: 'it', label: 'Italiano'},
    {value: 'es', label: 'Español'},
    {value: 'de', label: 'Deutsch'},
]

const LanguageSelect = () => {

    const {t, i18n} = useTranslation();
    const {call} = useApi();

    const onChange = (e) => {
        const value = e.target.value;
        changeLanguageHandler(value);
    }

    const changeLanguageHandler = (lang) => {
        i18n.changeLanguage(lang);
    }

    const [languages, setLanguages] = useState([])
    useEffect(() => {
        const browserLanguage = (navigator.language).split('-')[0];
        const defaultLanguage = VALUES.find((lang) => lang.value === browserLanguage) ? browserLanguage : 'en';
        i18n.changeLanguage(defaultLanguage);

        const fetchData = async () => {
            try {
                await call(
                    () => doGetLanguages(),
                    (response) => {
                        if (response.data) {
                            setLanguages(response.data);
                        }
                    }
                )
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);


    return (
        <div className="flex flex-row items-center gap-1 justify-end text-sm text-white">
            <div>{t('utility.select_language')}:</div>
            <select onChange={onChange} className="bg-transparent font-bold" value={i18n.language}>
                {languages.map((option) =>
                    <option
                        className="text-black"
                        value={option.cotLang}
                        key={option.cotLang}
                    >
                        {option.cotLang}
                    </option>)}
            </select>
        </div>

    );
};

export default LanguageSelect;
