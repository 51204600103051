import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDish} from "../../../../utils/contexts/dish-context";
import {doPostAiDishDescription, doPostAiDishImage} from "../../../../logic/dish";

import OverlayContainer from "../../../UI/OverlayContainer";
import Loading from "../../../UI/Loading";
import {GiArtificialHive} from "react-icons/gi";
import Button from "../../../UI/Button";

const AiDishDescriptionForm = () => {

    const {t} = useTranslation();
    const {dish, closeAiDishDescriptionModal, inputDishName} = useDish();

    const [loading, setLoading] = useState(true);
    const [loadingImage, setLoadingImage] = useState(false);
    const [aiDishDescription, setAiDishDescription] = useState(null);
    const [aiDishImage, setAiDishImage] = useState(null);

    const loadImage = async () => {
        setLoadingImage(true)

        let payload = {
            name: aiDishDescription.name,
            description: aiDishDescription.description
        }

        let response = await doPostAiDishImage(payload);
        if (response) {
            setAiDishImage(response.data.data.response.data[0].url);

        }
        setLoadingImage(false);
    }

    const load = async () => {
        setLoading(true)
        let mapped_ingredients = dish.ingredients.map(ingredient => {
            return ingredient.it_name;
        });

        let payload = {
            name: inputDishName,
            ingredients: mapped_ingredients
        }

        let response = await doPostAiDishDescription(payload);

        if (response) {
            setAiDishDescription(response.data.data);

        }
        setLoading(false);
    }

    useEffect(() => {
        if (dish)
            load();
    }, []);


    return (
        <OverlayContainer>
            <div className="p-4 bg-white rounded shadow-2xl" style={{minWidth: '300px', position: 'relative'}}>

                {loading ?
                    <Loading label={'wait for Description'}/>
                    :
                    aiDishDescription ?
                        <>
                            <div className="mb-2 font-bold uppercase text-gray-700 ">{aiDishDescription.name}</div>
                            {aiDishDescription.description}
                            <Button
                                onClick={() => loadImage()}
                                className='uppercase flex flex-row items-center gap-1 my-2'>
                                <GiArtificialHive/> {t('calculate_impact.generate_ai_dish_image')}
                            </Button>
                            {loadingImage ? <Loading label={'wait for Image'}/>
                                :
                                aiDishImage ?
                                    <img src={aiDishImage} alt="AI Dish"/> :
                                    <></>
                            }
                        </>

                        :
                        <div>Ai Description not loaded</div>
                }
                <div>
                    <Button
                        className="hover:underline font-medium mt-2 mr-2"
                        style={{position: 'absolute', top: '0', right: '0'}}
                        onClick={closeAiDishDescriptionModal}>{t('Close')}</Button>
                </div>
            </div>
        </OverlayContainer>
    );
};

export default AiDishDescriptionForm;
