import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import Routing from "./components/navigation/Routing";
import './index.css';
import {AuthProvider} from "./utils/contexts/auth-context";
import {AppProvider} from "./utils/contexts/app-context";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AppProvider>
            <AuthProvider>
                <Routing/>
            </AuthProvider>
        </AppProvider>
    </React.StrictMode>
);
