import React from 'react';

const DietSelector = ({diets, addToSelected}) => {


    const handleChange = (e) => {
        const selectedDiet = diets.find(diet => diet.id === parseInt(e.target.value));
        addToSelected(selectedDiet);
    }


    return (
        <div>
            <h1 style={{fontSize: 'x-large', color: '#1e40af'}}>Select Diet:</h1>
            <select
                onChange={handleChange}
                style={{fontSize: 'x-large'}}
            >
                {diets.map(diet =>
                    <option key={diet.id} value={diet.id}>{diet.name}</option>
                )}
            </select>
        </div>
    );
};

export default DietSelector;
