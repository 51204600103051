import React, {useState} from 'react';

const AuthContext = React.createContext(null);

function useAuthContextValue() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [userName, setUserName] = useState("");

    const login = () =>
        setLoggedIn(true);

    return {
        loggedIn, login, setLoggedIn,
        userName, setUserName
    }
}

function AuthProvider({...props}) {
    const value = useAuthContextValue();
    return <AuthContext.Provider value={value} {...props}>{props.children}</AuthContext.Provider>
}

function useAuth() {
    const context = React.useContext(AuthContext);
    if (!context) throw new Error('useAuth must be used within a authContext');
    return context;
}

export {
    AuthProvider,
    useAuth,
}
