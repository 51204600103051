import React from 'react';

const IngredientOption = ({ingredient, onClick, dishIngredients = []}) => {

    const onClickHandler = () => {
        onClick && onClick(ingredient)
    }

    const getSelected = () => {
        return dishIngredients.some(ingr => ingredient.id.toString() === ingr.id.toString());
    }

    return (
        <div
            className={`hover:bg-opacity-50 text-xs hover:text-black cursor-pointer border-b p-0.5 border-gray-500 ${getSelected() ? "bg-green-500" : ""}`}
            onClick={onClickHandler}>{ingredient.text ?? ingredient.name}</div>
    );
};

export default IngredientOption;