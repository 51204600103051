import axios from 'axios';

const API_URL = process.env.REACT_APP_V3_ENDPOINT;
const USER = process.env.REACT_APP_AUTH_API_USER;
const PASSWORD = process.env.REACT_APP_AUTH_API_PASSWORD;

const apiV3 = async (endpoint, method = 'GET', data = null, contentType = 'application/json') => {
    const api_credentials = btoa(`${USER}:${PASSWORD}`);
    let access_token = sessionStorage.getItem('@auth_token');


    try {
        await fetch(API_URL, {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${api_credentials}`,
                'Content-Type': 'application/json',
                'x-auth-token': access_token
            },
        });

        const config = {
            method,
            url: `${API_URL}${endpoint}`,
            headers: {
                'Authorization': `Basic ${api_credentials}`,
                'Content-Type': contentType,
                'x-auth-token': access_token
            },
            data: contentType === 'application/x-www-form-urlencoded' ? new URLSearchParams(data).toString() : data,
        };

        return await axios(config);
    } catch (err) {
        console.error('apiV3 request error:', err);
        throw err;
    }
};

apiV3.get = (endpoint) => apiV3(endpoint, 'GET');
apiV3.post = (endpoint, data) => apiV3(endpoint, 'POST', data);
apiV3.put = (endpoint, data) => apiV3(endpoint, 'PUT', data);
apiV3.delete = (endpoint) => apiV3(endpoint, 'DELETE');
apiV3.postEncoded = (endpoint, data) => apiV3(endpoint, 'POST', data, 'application/x-www-form-urlencoded');

export default apiV3;
