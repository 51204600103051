import apiV3 from "../utils/apiV3";


async function doGetIngredients(barcode, lang = 'en') {
    return await apiV3.get(`products/envimpact/${barcode}/ingredients`);
}

async function doGetOffData(barcode, lang = 'en') {
    return await apiV3.get(`products/off/${barcode}`);
}

function doPostOffData(barcode, data, lang = 'en') {
    return apiV3.postEncoded(`products/off/${barcode}`, data);
}

async function doGetAllAllergies(lang = 'en') {
    const response = await apiV3.get(`allergies`);
    return response.data.response
}

async function doGetAllDiets(lang = 'en') {
    const response = await apiV3.get(`diets`);
    return response.data.response
}


export {
    doGetIngredients, doGetOffData, doPostOffData, doGetAllAllergies, doGetAllDiets
}
