import React, {useState} from 'react';

const AppContext = React.createContext(null);

function useAppContextValue () {

    const [toastStatus, setToastStatus] = useState(false);
    const [toastContent, setToastContent] = useState(null);

    return {
        toastStatus, setToastStatus,
        toastContent, setToastContent,
    }
}

function AppProvider({...props}) {
    const value = useAppContextValue();
    return <AppContext.Provider value={value} {...props}>{props.children}</AppContext.Provider>;
}

function useApp() {
    const context = React.useContext(AppContext);
    if(!context) throw new Error('useApp must be used within an AppProvider');
    return context;
}

export {
    AppProvider,
    useApp,
}
