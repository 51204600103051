import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import {useApp} from "../../../utils/contexts/app-context";

const Layout = ({...props}) => {

    const {toastContent, toastStatus} = useApp();

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <div className="flex-1">
                {props.children}
            </div>
            <Footer />
            {toastStatus &&
            <div className="fixed bottom-0 left-0 right-0 flex flex-row justify-center pointer-events-none">
                {toastContent}
            </div>
            }
        </div>
    );
};

export default Layout;