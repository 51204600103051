import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Button from "../UI/Button";
import {useToast} from "../../utils/hooks/toast-hook";
import {doGetAllAllergies, doGetAllDiets} from "../../logic/barcode";
import AllergySelector from './AllergySelector';
import SelectedAllergies from './SelectedAllergies';
import DietSelector from "./DietSelector";
import SelectedDiets from "./SelectedDiets";

const UserInfoSelectors = ({onclose}) => {
    const navigate = useNavigate();
    const {displayError, displayMessage} = useToast()
    const [allergies, setAllergies] = useState([])
    const [selectedAllergies, setSelectedAllergies] = useState([])
    const [diets, setDiets] = useState([])
    const [selectedDiets, setSelectedDiets] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await doGetAllAllergies();
                setAllergies(res)
            } catch (err) {
                displayError("Error getting Allergies", err);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await doGetAllDiets();
                setDiets(res)
            } catch (err) {
                displayError("Error getting Diets", err);
            }
        };

        fetchData();
    }, []);

    const addToSelectedAllergy = (allergy) => {
        setSelectedAllergies(oldSelected => [...oldSelected, allergy]);
    }

    const deleteFromSelectedAllergy = (allergy) => {
        setSelectedAllergies(oldSelected => oldSelected.filter(a => a !== allergy));
    }

    const addToSelectedDiet = (diet) => {
        setSelectedDiets(oldSelected => [...oldSelected, diet]);
    }

    const deleteFromSelectedDiet = (diet) => {
        setSelectedDiets(oldSelected => oldSelected.filter(a => a !== diet));
    }

    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                gap: 10
            }}>


                {diets &&
                    <div className={'mt-2 flex flex-col'}
                         style={{
                             border: '1px solid #1e40af',
                             borderRadius: '8px',
                             padding: '10px'
                         }}>
                        <DietSelector diets={diets}
                                      addToSelected={addToSelectedDiet}/>
                        <SelectedDiets selectedDiets={selectedDiets}
                                       deleteFromSelected={deleteFromSelectedDiet}/>
                    </div>}


                {allergies &&
                    <div
                        className={'mt-2 flex flex-col'}
                        style={{
                            border: '1px solid #1e40af',
                            borderRadius: '8px',
                            padding: '10px'
                        }}
                    >
                        <AllergySelector allergies={allergies} addToSelected={addToSelectedAllergy}/>
                        <SelectedAllergies selectedAllergies={selectedAllergies}
                                           deleteFromSelected={deleteFromSelectedAllergy}/>
                    </div>}

            </div>

        </>
    );
};

export default UserInfoSelectors;
