import React from 'react';
import {FaRegCheckCircle, FaRegTimesCircle} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const DietIcon = ({diet}) => {

    const {t} = useTranslation();

    return (
        <div className="flex flex-row items-center gap-1">
            <div>{diet.compatibile ?
                <FaRegCheckCircle className="text-green-500"/>
                : <FaRegTimesCircle className="text-red-500"/>
            }</div>
            <div className={`${diet.compatibile ? "text-green-500" : "text-red-500"}`}>{t(`diets.${diet.dieta}`)}</div>
        </div>
    );
};

export default DietIcon;