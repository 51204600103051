import React, {useEffect, useState} from 'react';
import {useAuth} from "../../utils/contexts/auth-context";
import {Navigate} from "react-router-dom";
import {doGetWhoAmI} from "../../logic/auth";
import {useApi} from "../../utils/hooks/api";

const ProtectedRoute = ({...props}) => {
    const {setLoggedIn} = useAuth();
    const {call} = useApi();


    useEffect(() => {
        const load = () => {
            call(
                () => doGetWhoAmI(),
                (res) => {
                    setLoggedIn(true);
                },
                () => {
                    sessionStorage.clear();
                    setLoggedIn(false)
                }
            )
        }
        load();
    }, []);

    
    const token = sessionStorage.getItem('@auth_token');
    if (!token) {
        console.warn('You are not logged in');
        return (
            <Navigate to={'/login'} replace/>
        );
    } else
        return (
            <>
                {props.children}
            </>
        )
};

export default ProtectedRoute;
