import React, {useState, useEffect} from 'react';
import {CiCircleRemove} from "react-icons/ci";
import {useDish} from "../../utils/contexts/dish-context";

const SelectedDiets = ({selectedDiets, deleteFromSelected}) => {
    const {filteredDiets, setFilteredDiets} = useDish()

    useEffect(() => {
        let uniqueAllergies = [...new Map(selectedDiets.map(allergy => [allergy.id, allergy])).values()];
        setFilteredDiets(uniqueAllergies);
    }, [selectedDiets]);

    const handleClick = (allergy) => () => {
        deleteFromSelected(allergy);
    }

    return (
        <div>
            {filteredDiets.map(allergy => (
                <div key={allergy.id} style={{fontSize: 'x-large'}}>
                    {allergy.name}
                    <button className={'ml-2'} onClick={handleClick(allergy)}><CiCircleRemove/></button>
                </div>
            ))}
        </div>
    );
};

export default SelectedDiets;
