import React, {useState} from 'react';
import {doPostTestDish} from "../../logic/dish";
import {useTranslation} from "react-i18next";
import {useDish} from "../../utils/contexts/dish-context";
import Button from "../UI/Button";
import BaseInput from "../UI/BaseInput";
import Loading from "../UI/Loading";
import Description from "../UI/Text/Description";
import {useApi} from "../../utils/hooks/api";

const DishNameInput = () => {

    const {setDish} = useDish();
    const {t, i18n} = useTranslation();
    const {call, loading} = useApi();

    const {inputDishName, setInputDishName} = useDish()

    const handleSubmit = async (e) => {
        e.preventDefault();

        await call(
            () => doPostTestDish(inputDishName, i18n.language),
            (res) => {
                if (res && res.data)
                    setDish(res.data);
            }
        )
    }

    return (
        <div>
            <Description>{t('calculate_impact.dish_input_text')}</Description>
            <form className="flex flex-col sm:flex-row gap-2" onSubmit={handleSubmit}>
                <BaseInput className="w-full"
                           placeholder={t('calculate_impact.dish_input_placeholder')}
                           value={inputDishName}
                           required={true}
                           onChange={(e) => setInputDishName(e.currentTarget.value)}/>
                {loading ? <Loading label="Computing..."/>
                    : <Button type='submit'>{t('calculate_impact.dish_input_button')}</Button>
                }
            </form>
        </div>
    );
};

export default DishNameInput;
