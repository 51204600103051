import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDish} from "../../utils/contexts/dish-context";
import Button from "../UI/Button";
import {useApi} from "../../utils/hooks/api";
import {useToast} from "../../utils/hooks/toast-hook";
import {useTranslation} from "react-i18next";
import AiDishDescriptionForm from "../CalculateImpact/Dish/AI/AiDishDescriptionForm";
import {doGetAllergens, doGetDiets, doPostTestDish} from "../../logic/dish";
import i18n from "i18next";

const IMAGE_ENDPOINT = process.env.PUBLIC_URL + "/assets/";


const Gs1DataDisplay = ({onclose}) => {
    const navigate = useNavigate();
    const {call, loading} = useApi();
    const {
        gs1Data,
        setBarcode,
        offData,
        openAiDishDescriptionModal,
        aiDishDescriptionModalStatus,
        setAllergens,
        setDiets,
        allergens,
        diets,
        dish, setDish
    } = useDish();
    const {displayError, displayMessage} = useToast()
    const {t} = useTranslation()

    const [toSave, setToSave] = useState(false)
    const [saved, setSaved] = useState(false)

    const handleGdo = () => {
        setDish(undefined);
        setDiets(undefined);
        setAllergens(undefined);
        setBarcode(undefined);
        navigate('/gdo');
    }


    const handleGetMyCiaAllergens = async () => {
        if (gs1Data) {
            await call(
                () => doPostTestDish(gs1Data.name, i18n.language),
                async (res) => {
                    if (res && res.data) {
                        setDish(res.data)
                        let allergens_response = await doGetAllergens(res.data.id);
                        if (allergens_response) {
                            if (allergens_response.response) {
                                setAllergens(allergens_response.response);
                            }
                        }
                        let diet_response = await doGetDiets(res.data.id);
                        if (diet_response) {
                            if (diet_response.response) {
                                setDiets(diet_response.response);
                            }
                        }
                    }

                }
            )
        }
    }

    useEffect(() => {
        handleGetMyCiaAllergens()
    }, []);


    return (
        <>
            {(!toSave && !saved) ?
                <>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh'
                    }}>
                        <div style={{
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            padding: '20px',
                            maxWidth: '500px',
                            width: '100%',
                            textAlign: 'center',
                            position: 'relative'
                        }}>
                            <p className={'mb-8'}><strong>dati raccolti da GS1</strong></p>

                            {gs1Data ?
                                <>
                                    <p style={{padding: '0.5rem 0'}}><strong>Nome:</strong> {gs1Data.name}</p>
                                    <p style={{padding: '0.5rem 0'}}>
                                        <strong>Categoria:</strong> {gs1Data.category.description}
                                    </p>
                                    <p style={{padding: '0.5rem 0'}}>
                                        <strong>Allergeni:</strong> {gs1Data.metadata.allergens}
                                    </p>
                                    <p style={{padding: '0.5rem 0'}}>
                                        <strong>Ingredienti:</strong> {gs1Data.metadata.ingredients[0]}
                                    </p>

                                    {/*{!dish &&*/}
                                    {/*    <Button className={'mt-1'}*/}
                                    {/*            onClick={handleGetMyCiaAllergens}>GET MYCIA ALLERGENS & DIETS</Button>}*/}

                                    {dish &&
                                        <>
                                            <hr style={{border: '1px solid #ccc', margin: '1rem 0'}}/>
                                            <p className={'mb-8'}><strong>MYCIA ALLERGENS & DIETS</strong></p>
                                        </>

                                    }

                                    {allergens &&
                                        <div>
                                            <p className={'mt-8'}><strong>ALLERGENS</strong></p>
                                            {allergens.map((allergen) => (
                                                <div key={allergen.id_dieta} style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: '0.5rem'
                                                }}>
                                                    <img
                                                        className="w-6 h-6 object-contain text-xs"
                                                        src={IMAGE_ENDPOINT + allergen.allergia_icona}
                                                        alt={t('Allergen image')}
                                                    />
                                                    {allergen.allergia_nome}
                                                </div>


                                            ))}
                                        </div>
                                    }
                                    {diets &&
                                        <div>
                                            <p className={'mt-8'}><strong>DIETS</strong></p>
                                            {diets.map((diet) => (
                                                <p key={diet.id_dieta}>
                                                    {diet.dieta}
                                                </p>
                                            ))}
                                        </div>
                                    }

                                </>
                                :
                                <p>NO DATA</p>
                            }

                            <hr style={{border: '1px solid #ccc', margin: '1rem 0'}}/>


                            <p className={'mb-8'}><strong>dati raccolti da Open Food Facts</strong></p>
                            {offData ?
                                <>
                                    <p><strong>Name:</strong> {offData.name}</p>
                                    <p><strong>Brand:</strong> {offData.brand}</p>
                                    <p><strong>Ingredients:</strong> {offData.ingredients}</p>
                                    <p><strong>Nutrient Levels:</strong></p>
                                    <p><strong>Fat:</strong> {offData.nutrient_levels.fat}</p>
                                    <p><strong>Salt:</strong> {offData.nutrient_levels.salt}</p>
                                    <p><strong>Saturated Fat:</strong> {offData.nutrient_levels['saturated-fat']}</p>
                                    <p><strong>Sugars:</strong> {offData.nutrient_levels.sugars}</p>
                                    <img src={offData.front_image} alt="Product"
                                         style={{maxWidth: '50%', height: 'auto', margin: '20px auto'}}/>
                                    <p><strong>Last Edit Date:</strong> {offData.last_edit_date.toLocaleString()}</p>
                                </>
                                :
                                <p>NO DATA</p>

                            }

                            <div style={{position: 'absolute', bottom: '-20px', left: '8px'}}>
                                <Button style={{width: '130px'}}
                                        onClick={
                                            handleGdo
                                        }>CLOSE</Button>
                            </div>
                            {/*<div style={{position: 'absolute', bottom: '-20px', right: '8px'}}>*/}
                            {/*    <Button style={{width: '130px'}}*/}
                            {/*            onClick={handleAddOffData}>SAVE TO MYCIA</Button>*/}
                            {/*</div>*/}


                        </div>
                    </div>

                </>
                :
                <>

                    <div style={{
                        borderRadius: '5px',
                        padding: '20px',
                        maxWidth: '500px',
                        width: '100%',
                        textAlign: 'center',
                        position: 'relative',

                    }}>
                        <p><strong></strong></p>
                        <Button style={{width: '130px', marginTop: '10px'}}
                                onClick={handleGdo}
                        >TORNA A GDO</Button>
                    </div>
                </>
            }

            {gs1Data && aiDishDescriptionModalStatus &&
                <AiDishDescriptionForm/>
            }

        </>


    );
};

export default Gs1DataDisplay;
