import React from 'react';

const Description = ({className, ...props}) => {
    return (
        <div {...props} className={`${className} text-gray-600`}>
            {props.children}
        </div>
    );
};

export default Description;