import React from 'react';
import CompoundName from "../Ingredients/CompoundName";
import {useTranslation} from "react-i18next";
import {useDish} from "../../../utils/contexts/dish-context";

const DishPhrase = () => {

    const {dish, diets, allergens} = useDish();

    const {t} = useTranslation();

    const getIngredientNames = () => {
        return dish.ingredients.map(ingr => " " + ingr.name).toString();
    }

    const hasComposti = () => {
        return dish.ingredients.filter(ingr => ingr.compound === 1).length > 0;
    }

    const getCompostiNames = () => {
        return dish.ingredients
            .filter(ingr => ingr.compound === 1)
            .map(ingr =>
                <CompoundName ingredient={ingr}
                              key={ingr.id}/>
            );
    }

    const getAllergensNames = () => {
        return allergens.map(allergen => " " + t(`allergens.${allergen.allergia_nome}`)).toString();
    }

    const getDietsNames = () => {
        return diets.filter(diet => !diet.compatibile).map(diet => " " + t(`diets.${diet.dieta}`)).toString();
    }

    return (
        <div className="text-sm italic">
            <div>
                <span className="uppercase">{t('calculate_impact.the_dish_has_the_following_ingredients')}:</span>
                <span className="font-medium">{getIngredientNames()} </span>
                {hasComposti() && <div className="flex flex-row gap-1">
                    <span className="uppercase">({t('calculate_impact.of_which_compound')}: </span>
                    <div className="flex flex-row gap-1 font-medium">{getCompostiNames()}</div>
                    )</div>}
            </div>
            {diets && diets.length > 0 &&
                <div>
                    <span
                        className="uppercase">{t('calculate_impact.the_dish_is_not_suitable_for_the_following_diets')}: </span>
                    <span className="font-medium">{getDietsNames()}</span>
                </div>
            }
            {allergens && allergens.length > 0 &&
                <div>
                    <span className="uppercase">{t('calculate_impact.also_it_has_the_following_allergens')}: </span>
                    <span className="font-medium">{getAllergensNames()}</span>
                </div>
            }
        </div>
    );
};

export default DishPhrase;
