import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {doGetIngredientImpactDetail} from "../../../../logic/ingredients";
import Tooltip from "../../../UI/Tooltip";
import Loading from "../../../UI/Loading";

const ImpactIngredientTooltip = ({ingredient}) => {

    const {t, i18n} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [impact, setImpact] = useState(null);

    const handleHover = async () => {
        if(!impact) {
            setLoading(true);
            let response = await doGetIngredientImpactDetail(ingredient.id, i18n.language);
            if(response) {
                setImpact(response);
            }
            setLoading(false);
        }
    }

    return (
        <Tooltip onMouseEnter={handleHover}
            tooltip={
            <div style={{minWidth: "110px"}}>
                {loading?
                    <Loading label={t('Loading impact for 100g')} />
                    :
                    <div>
                        <div className="uppercase text-gray-500 font-medium">{ingredient.name} - {t('Impact for 100g')}:</div>
                        {impact ?
                            <div className="">
                                <div>CO2: <span className="font-medium">{impact?.co2?.value}</span>{impact?.co2?.unit}</div>
                                <div>H2O: <span className="font-medium">{impact?.h2o?.value}</span>{impact?.h2o?.unit}</div>
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                }
            </div>
        }>
            {ingredient.name}
        </Tooltip>
    );
};

export default ImpactIngredientTooltip;
