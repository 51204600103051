import React, {useState} from 'react';
import Loading from "../../UI/Loading";
import Description from "../../UI/Text/Description";
import {doDeleteDish, doGetDish, doGetDishes} from "../../../logic/dish";
import {useDish} from "../../../utils/contexts/dish-context";
import {useTranslation} from "react-i18next";
import TestedDishItem from "./TestedDishItem";
import {useApi} from "../../../utils/hooks/api";

const HistoryOfSearchedDishes = () => {

    const {setDish, dish} = useDish();
    const {t, i18n} = useTranslation();
    const {call, loading} = useApi();

    const [historyOpen, setHistoryOpen] = useState(false);
    const [dishes, setDishes] = useState([]);

    const handleSelectDish = async (dish) => {
        if (dish && dish.id) {
            await call(
                () => doGetDish(dish.id, i18n.language),
                (response) => {
                    if (response.data[0]) {
                        setDish(response.data[0]);
                    }
                }
            )
            setHistoryOpen(false);
        }
    }

    const handleDeleteDish = async (_dish) => {
        if (_dish && _dish.id) {
            await call(
                () => doDeleteDish(_dish.id),
                (response) => {
                    if (response) {
                        if(dish.id === _dish.id) setDish(null);
                        if (dishes.some(d => d.id === _dish.id)) {
                            setDishes([...dishes.filter(d => d.id !== _dish.id)]);
                        }
                    }
                }
            );
        }
    }

    const handleToggleOpen = async () => {
        if (!historyOpen) {
            setHistoryOpen(true);
            await call(
                () => doGetDishes(),
                (response) => {
                    if (response.data) {
                        setDishes(response.data);
                    }
                }
            )
        } else {
            setHistoryOpen(false);
        }
    }

    return (
        <div>
            <button type='' onClick={handleToggleOpen} className="hover:underline my-2 text-sm text-gray-700">Recently searched dishes</button>
            {historyOpen &&
                <div className="border border-gray-200 rounded p-1 " >
                    <Description className="text-sm mb-1 italic">Click on a dish to load its data</Description>
                    {loading ? <Loading label="Loading dishes"/>
                        :dishes && dishes.length > 0 ?
                            <div className="flex flex-row gap-2 flex-wrap overflow-y-auto" style={{maxHeight: "300px"}}>
                                {dishes.map((dish, i) => <TestedDishItem dish={dish}
                                                                         onDelete={handleDeleteDish}
                                                                         onSelect={handleSelectDish}
                                                                         key={i}/>)}
                            </div>
                            :
                            <div>{t('Empty history of tested dishes')}</div>
                    }
                </div>
            }
        </div>
    );
};

export default HistoryOfSearchedDishes;