import React from 'react';

const SubTitle = ({className, ...props}) => {
    return (
        <h2 className={`${className} text-gray-600 uppercase font-medium`}>
            {props.children}
        </h2>
    );
};

export default SubTitle;