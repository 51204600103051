import apiV3 from "../utils/apiV3";

const ENDPOINT = process.env.REACT_APP_V3_ENDPOINT;

async function doGetCompoundIngredients(ingredient_id, lang = 'en') {
    let response = await apiV3.get(ENDPOINT + `foods/${ingredient_id}?ingredienti=1&lang=${lang}`);
    if (response) return response.data;

}

async function doGetIngredientImpactDetail(ingredient_id, lang = 'en') {
    let response = await apiV3.get(ENDPOINT + `foods/${ingredient_id}/impact?lang=${lang}`);
    if (response) return response.data;
}

async function doPutIngredientsWeights(dish_id, ingredients, lang = 'en') {
    return await apiV3.put(`envimpact/restaurants/1/courses/1/dishes/${dish_id}/ingredients/weights?lang=${lang}`, {ingredients: [...ingredients]});
}

export {
    doGetCompoundIngredients,
    doGetIngredientImpactDetail,
    doPutIngredientsWeights,
}