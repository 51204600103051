import React from 'react';
import {FaTrashAlt} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const TestedDishItem = ({dish, onSelect, onDelete}) => {

    const {t} = useTranslation();

    const handleClick = () => {
        onSelect && onSelect(dish);
    }

    const handleDelete = (e) => {
        e.stopPropagation();
        onDelete && onDelete(dish);
    }

    return (
        <div onClick={handleClick} className="text-xs bg-gray-100 p-0.5 rounded flex flex-row gap-1 items-center cursor-pointer hover:bg-gray-400 hover:text-white">
            <div>{dish.pia_nome ?? "Nome non disponibile"}</div>
            <FaTrashAlt onClick={handleDelete}
                className="cursor-pointer hover:text-red-500"
                        title={t('Delete from history')} />
        </div>
    );
};

export default TestedDishItem;