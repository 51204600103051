import React from 'react';

const AllergySelector = ({allergies, addToSelected}) => {


    const handleChange = (e) => {
        const selectedAllergy = allergies.find(allergy => allergy.id === parseInt(e.target.value));
        addToSelected(selectedAllergy);
    }


    return (
        <div>
            <h1 style={{fontSize: 'x-large', color: '#1e40af'}}>Select Allergies: </h1>
            <select
                onChange={handleChange}
                style={{fontSize: 'x-large'}}
            >
                {allergies.map(allergy =>
                    <option key={allergy.id} value={allergy.id}>{allergy.name}</option>
                )}
            </select>
        </div>
    );
};

export default AllergySelector;
