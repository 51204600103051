import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDish} from "../../../../utils/contexts/dish-context";
import {doGetAllergens} from "../../../../logic/dish";
import AllergenIcon from "./AllergenIcon";
import SubTitle from "../../../UI/Text/SubTitle";
import Loading from "../../../UI/Loading";
import {useToast} from "../../../../utils/hooks/toast-hook";

const Allergens = () => {

    const {dish, allergens, setAllergens} = useDish();
    const {t} = useTranslation();
    const {displayWarning} = useToast();
    const [allergensLoading, setAllergensLoading] = useState(false);

    const reload = async () => {
        setAllergensLoading(true);

        try {
            let allergens_response = await doGetAllergens(dish.id);
            if (allergens_response) {
                if (allergens_response.response) {
                    setAllergens(allergens_response.response);
                }
            }
        } catch (e) {
            displayWarning('Error detecting allergens');
        }


        setAllergensLoading(false);
    }

    useEffect(() => {
        if (dish && dish.id)
            reload();
    }, [dish]);

    return (
        <div className="flex-1">
            <SubTitle>{t('calculate_impact.allergens')}</SubTitle>
            {allergensLoading ?
                <div className="flex flex-row justify-start mb-2">
                    <Loading label={t("Loading allergens")}/>
                </div>
                :
                <div className="flex flex-row flex-wrap gap-2">
                    {allergens && allergens.length > 0 ?
                        allergens.map((allergen, i) => <AllergenIcon allergen={allergen} key={i}/>)
                        : <div className="text-gray-300 italic text-sm">{t('allergens.no_allergen')}</div>
                    }
                </div>
            }

        </div>
    );
};

export default Allergens;
